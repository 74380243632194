import React from 'react';
import Helmet from 'react-helmet';
import ReactPlayer from 'react-player';
import { RouteComponentProps } from '@reach/router';
import { graphql } from 'gatsby';

import Section from '@nib-components/section';
import Heading from '@nib-components/heading';
import Container from '@nib-components/container';
import Copy from '@nib-components/copy';
import colors from '@nib-components/colors';
import Link from '@nib-components/link';
import { Stack, Inline } from '@nib/layout';
import { TwitterSystemIcon, FacebookSystemIcon, EmailSystemIcon } from '@nib/icons';

import Layout from '../components/Layout';
import metrics from '../metrics';
import { ContentfulNewsArticle, MarkdownHtml } from '../constructs/models';
import { getFormattedDateFromDateString } from '../utils/moment-utils';
import { GatsbyImage } from 'gatsby-plugin-image';

export const newsDetailsPageQuery = graphql`
  query ($slug: String!) {
    allContentfulNewsArticle(filter: { urlSlug: { eq: $slug } }) {
      edges {
        node {
          id
          heroType
          title
          htmlTitle
          urlSlug
          videoUrl
          startDate
          metaDataPageDescription {
            metaDataPageDescription
          }
          seoSchema {
            internal {
              content
            }
          }
          summary {
            summary
          }
          articleContent {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            file {
              url
            }
            gatsbyImageData(layout: FULL_WIDTH)
            title
          }
        }
      }
    }
  }
`;

interface GraphQlNewsArticle {
  allContentfulNewsArticle: AllContentfulNewsArticle;
}

interface AllContentfulNewsArticle {
  edges: ContentfulNewsArticleEdgesShape[];
}

interface ContentfulNewsArticleEdgesShape {
  node: ContentfulNewsArticle;
}

interface NewsDetailsProps extends RouteComponentProps {
  data: GraphQlNewsArticle;
}

const NewsDetails = (props: NewsDetailsProps): JSX.Element => {
  const { data } = props;
  const articleData = data.allContentfulNewsArticle.edges[0].node;

  const { title, htmlTitle, metaDataPageDescription, articleContent, seoSchema } = articleData;
  return (
    <Layout>
      <div>
        <Helmet>
          <title>{htmlTitle || title} | nib</title>
          {metaDataPageDescription?.metaDataPageDescription && (
            <meta name="description" content={metaDataPageDescription?.metaDataPageDescription} />
          )}
          {seoSchema?.internal?.content && (
            <script type="application/ld+json">{seoSchema?.internal?.content}</script>
          )}
        </Helmet>
        <Section>
          <Stack space={4}>
            <HeaderSection title={title} />
            <HeroSection article={articleData} />
            <ContentSection content={articleContent} />
          </Stack>
        </Section>
      </div>
    </Layout>
  );
};

interface HeaderSectionProps {
  title: string;
}

const HeaderSection = (props: HeaderSectionProps): JSX.Element => {
  const { title } = props;

  return (
    <Container>
      <Heading size={{ sm: 2, lg: 1 }} component="h1">
        {title}
      </Heading>
    </Container>
  );
};

interface HeroSectionProps {
  article: ContentfulNewsArticle;
}

const HeroSection = (props: HeroSectionProps): JSX.Element => {
  const { article } = props;

  const { startDate, heroImage, heroType, videoUrl, urlSlug, title, summary } = article;

  return (
    <Container>
      <Stack space={1}>
        <Copy color={colors.grumpy}>{getFormattedDateFromDateString(startDate)}</Copy>
        {heroType === 'Image' ? (
          <HeroImage
            gatsbyImageData={(heroImage && heroImage.gatsbyImageData) || ''}
            alt={(heroImage && heroImage.title) || ''}
          />
        ) : (
          <HeroVideo url={videoUrl || ''} />
        )}
        <ShareButtons urlSlug={urlSlug} title={title} summary={summary.summary} />
      </Stack>
    </Container>
  );
};

interface HeroImageProps {
  alt: string;
  gatsbyImageData: any;
}
const HeroImage = (props: HeroImageProps): JSX.Element => {
  return (
    <GatsbyImage
      image={props.gatsbyImageData}
      alt={props.alt}
      style={{ width: '100%' }}
      imgStyle={{ width: '100%' }}
    />
  );
};

interface HeroVideoProps {
  url: string;
}
const HeroVideo = (props: HeroVideoProps): JSX.Element => {
  return <ReactPlayer {...props} width="100%" controls={true} />;
};

interface ShareButtonsProps {
  urlSlug: string;
  title: string;
  summary: string;
}

const ShareButtons = (props: ShareButtonsProps): JSX.Element => {
  const { urlSlug, title, summary } = props;

  const url = `https://www.nib.co.nz/news-and-media/news-details/${urlSlug}`;
  const encodedUrl = encodeURI(url);
  const encodedTitle = encodeURI(title);
  const encodedEmailBody = encodeURI(`${summary}\n\n${url}`);

  const facebookShareHref = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
  const twitterShareHref = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}&via=nibNewZealand`;
  const mailShareHref = `mailto:?subject=${encodedTitle}&body=${encodedEmailBody}`;

  return (
    <Inline space={2} align="right">
      <Link href={facebookShareHref} color="dark" underline={false}>
        <FacebookSystemIcon />
      </Link>
      <Link href={twitterShareHref} color="dark" underline={false}>
        <TwitterSystemIcon />
      </Link>
      <Link href={mailShareHref} color="dark" underline={false}>
        <EmailSystemIcon />
      </Link>
    </Inline>
  );
};

const ContentSection = ({ content }: { content?: MarkdownHtml }): JSX.Element => (
  <Container>
    <Copy
      measure={false}
      component="div"
      dangerouslySetInnerHTML={{ __html: content?.childMarkdownRemark?.html }}
    />
  </Container>
);

export default metrics({ pageName: 'news-details' })(NewsDetails);
